

  .waviy {
    position: relative;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    font-size: 40px;
    text-transform: uppercase;
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i))
  }
  @keyframes flip {
    0%,80% {
      transform: rotateY(360deg) 
    }
  }
  
  